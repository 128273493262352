import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { DndProvider } from "react-dnd";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";

// styles
import "react-tooltip/dist/react-tooltip.css";
import "../DealsPage.scss";

// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import KanBanIcon from "../../../../_metronic/assets/icons/kanban.svg";
import ListIcon from "../../../../_metronic/assets/icons/list.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import { SVGICON } from "../../../../_metronic/helpers";

// types
import { Props, ErrorProps } from "../types/DealPropsType";
import { DealPipelineDetailsType } from "../types/DealResponseType";
import { PipelineType } from "../../pipelines/types/PipelinesResponseType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

// Shared Components
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";

import ErrorModal from "./ErrorModal";
import MovableDeal from "./DealCard";
import PhaseColumn from "./PhaseColumn";
import DealsListView from "./DealsListView";
import AddNewDealModal from "./AddNewDealModal";
import PipelineDetailsModal from "./PipelineDetailsModal";
import EndPhaseReasonModal from "./EndPhaseReasonModal";
import EndPhaseTransitionModal from "./EndPhaseTransitionModal";

// redux
import { RootState } from "../../../../setup";
import {
  changePhase,
  dealMoveToPipeline,
  getDealPipelineDetailsById,
} from "../redux/DealsAPI";
import { actions } from "../redux/DealsRedux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

const DealsList: FC<Props> = ({ pipelineId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const DEAL_VIEW_TYPES = JSON.parse(
    intl.formatMessage({ id: "DEAL_VIEW_TYPES" })
  ) as {
    id: number;
    name: string;
  }[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "deals");

  //   state
  const [pipeline, setPipeline] = useState<DealPipelineDetailsType[]>([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [items, setItems] = useState();
  const [currDealView, setCurrDealView] = useState<{
    id: number;
    name: string;
  }>(DEAL_VIEW_TYPES[1]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<ErrorProps>({
    show: false,
    dealDetails: undefined,
  });
  const [showEndPhaseModal, setShowEndPhaseModal] = useState<{
    show: boolean;
    from_phase_info: DealPipelineDetailsType | undefined;
    to_phase_info: DealPipelineDetailsType | undefined;
    pIndex: number;
    dIndex: number;
    hIndex: number;
  }>({
    show: false,
    from_phase_info: undefined,
    to_phase_info: undefined,
    pIndex: 0,
    dIndex: 0,
    hIndex: 0,
  });
  const [endReasonModal, setEndReasonModa] = useState<{
    show: boolean;
    value: number | undefined;
  }>({
    show: false,
    value: undefined,
  });
  const [openPipelineModal, setOpenPipelineModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [displayPipeline, setDisplayPipeline] =
    useState<DealPipelineDetailsType[]>();

  const getDealPhaseAPI = () => {
    getDealPipelineDetailsById(pipelineId)
      .then(({ data: { pipeline_deals } }) => {
        console.log(pipeline_deals, "data from edit deals");
        const result: DealPipelineDetailsType[] = [];
        pipeline_deals.map((val, index) => {
          if (
            val.condition !== "end" ||
            (val.condition === "end" && val.display_order)
          ) {
            result.push(val);
          }
          if (pipeline_deals.length - 1 === index) {
            result.push(
              pipeline_deals.filter((data) => data.condition === "end")[0]
            );
          }
        });
        setPipeline(result);
        dispatch(actions.setDealsDetails(result));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (pipelineId) {
      setLoading(true);
      getDealPhaseAPI();
      setCurrDealView(DEAL_VIEW_TYPES[1]);
    }
    dispatch(actions.setPhaseTransitionDetails([]));
  }, [pipelineId, pipelineList]);

  useEffect(() => {
    return () => {
      dispatch(
        actions.setDealFilterDetails({
          Responsible_Persons: [],
          status: "",
          partner: {},
          contact: {},
          tags: undefined,
        })
      );
    };
  }, []);

  const handlePhaseTransition = (
    from_phase_info: DealPipelineDetailsType,
    to_phase_info: DealPipelineDetailsType,
    pIndex: number,
    dIndex: number,
    hIndex: number
  ) => {
    // deal information
    const dealDetails = pipeline
      ?.filter((pipeline) => pipeline.phase_id === pIndex)[0]
      .deals?.filter((deal) => deal?.id === dIndex)[0];
    const isBackward =
      from_phase_info?.display_order && to_phase_info?.display_order
        ? Number(from_phase_info.display_order) >
          Number(to_phase_info.display_order)
        : from_phase_info?.condition === "end"
          ? true
          : to_phase_info?.condition === "start"
            ? true
            : false;

    if (isBackward) {
      if (pIndex !== hIndex) {
        changePhase(dealDetails?.id, pIndex, hIndex, null, 1)
          .then((data) => {
            successToast(
              intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" })
            );
          })
          .catch((e) => {
            errorToast(e?.response?.data?.message);
          })
          .finally(() => {
            getDealPhaseAPI();
          });
      }
    } else {
      if (
        to_phase_info?.condition === "end" &&
        dealDetails?.can_be_move &&
        to_phase_info.display_order === null
      ) {
        setEndReasonModa({
          ...endReasonModal,
          show: true,
        });
        setShowEndPhaseModal({
          show: false,
          from_phase_info,
          to_phase_info,
          pIndex,
          dIndex,
          hIndex,
        });
      } else if (
        to_phase_info?.condition === "end" &&
        dealDetails?.can_be_move &&
        to_phase_info.display_order !== null
      ) {
        setShowEndPhaseModal({
          show: false,
          from_phase_info,
          to_phase_info,
          pIndex,
          dIndex,
          hIndex,
        });
        setEndReasonModa({
          show: true,
          value: undefined,
        });
      } else {
        if (pIndex !== hIndex) {
          if (!dealDetails?.can_be_move) {
            if (pIndex !== hIndex) {
              dispatch(actions.setPhaseTransitionDetails([pIndex, hIndex]));
            }
            setShowErrorModal({
              show: true,
              dealDetails: dealDetails,
            });
          } else {
            if (pIndex !== hIndex) {
              // @ts-ignore
              handleDealTransition(dealDetails?.id, pIndex, hIndex);
            }
          }
        }
      }
    }
  };

  const handleDealTransition = (id: any, pIndex: any, hIndex: any) => {
    changePhase(id, pIndex, hIndex)
      .then((data) => {
        successToast(intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" }));
      })
      .catch((e) => {
        errorToast(intl.formatMessage({ id: "DEALS_MOVE_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        getDealPhaseAPI();
      });
  };

  const moveDealHandler = (pIndex: number, dIndex: number, hIndex: number) => {
    // phase information
    const from_phase_info = pipeline?.filter(
      (pipeline) => pipeline.phase_id === pIndex
    )[0];
    const to_phase_info = pipeline?.filter(
      (pipeline) => pipeline.phase_id === hIndex
    )[0];
    handlePhaseTransition(
      // @ts-ignore
      from_phase_info,
      to_phase_info,
      pIndex,
      dIndex,
      hIndex
    );
  };

  const handleDealMovetoAnotherPipeline = (
    id: number,
    pId: number,
    message?: string
  ) => {
    dealMoveToPipeline(id, pId)
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "DEAL_MOVE_ANOTHER_PIPELINE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorAlert(
          intl.formatMessage({
            id: "DEAL_MOVE_ANOTHER_PIPELINE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getDealPhaseAPI();
      });
  };

  const onSearchText = (value: string) => {
    setSearchText((value || "").trim());
  };

  const updatedPipelineSearchData = () => {
    const duplicatePipeline: DealPipelineDetailsType[] = [];

    const tempPipeline = _.cloneDeep(pipeline);
    if (tempPipeline && tempPipeline.length > 0)
      tempPipeline.map((phase) => {
        const duplicatePhase = _.cloneDeep(phase);
        const tempPhaseArray = _.cloneDeep(phase);
        tempPhaseArray.deals = [];
        phase.deals.map((deal) => {
          if (
            deal.name
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase()) &&
            ((phase.condition !== "end" && deal.status === "inProgress") ||
              (phase.condition === "end" &&
                ((phase.display_order === null && deal.status === "finished") ||
                  (phase.display_order !== null &&
                    deal.status === "inProgress"))))
          ) {
            tempPhaseArray?.deals?.push(deal);
          } else {
            const dealIndex = duplicatePhase.deals.findIndex(
              (dDeal) => dDeal.id === deal.id
            );
            duplicatePhase.deals.splice(dealIndex, 1);
          }
        });
        duplicatePipeline.push(duplicatePhase);
      });
    if (searchText && searchText.length > 0) {
      setDisplayPipeline(duplicatePipeline);
    } else {
      setDisplayPipeline(pipeline);
    }
  };

  useEffect(() => {
    updatedPipelineSearchData();

    return () => {
      setDisplayPipeline(pipeline);
    };
  }, [searchText, pipeline]);

  return (
    <>
      {showAddModal && (
        <AddNewDealModal
          show={showAddModal}
          closeModal={() => {
            setShowAddModal(false);
          }}
          getDealPhaseAPI={getDealPhaseAPI}
          pipelineId={pipelineId}
        />
      )}
      {showErrorModal.show && (
        <ErrorModal
          show={showErrorModal.show}
          closeModal={() => {
            setShowErrorModal({
              show: false,
              dealDetails: undefined,
            });
          }}
          type={"listModal"}
          tempModalProps={showErrorModal}
        />
      )}

      {openPipelineModal && (
        <PipelineDetailsModal
          show={openPipelineModal}
          closeModal={() => {
            setOpenPipelineModal(false);
          }}
          pipeline_id={pipelineId}
        />
      )}

      {showEndPhaseModal.show && (
        <EndPhaseTransitionModal
          show={showEndPhaseModal.show}
          closeModal={() => {
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: false,
            });
          }}
          onSubmitHandle={(value) => {
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: false,
            });
            setEndReasonModa({
              show: false,
              value: value,
            });
            if (value) {
              const dealDetails = pipeline
                ?.filter(
                  (pipeline) => pipeline.phase_id === showEndPhaseModal.pIndex
                )[0]
                .deals?.filter(
                  (deal) => deal.id === showEndPhaseModal.dIndex
                )?.[0];
              if (dealDetails?.id) {
                handleDealMovetoAnotherPipeline(dealDetails.id, value);
              }
            }
          }}
          id={pipelineId}
        />
      )}
      {endReasonModal.show && (
        <EndPhaseReasonModal
          show={endReasonModal.show}
          closeModal={() => {
            setEndReasonModa({
              ...endReasonModal,
              show: false,
            });
          }}
          onSubmit={(reason: string) => {
            setEndReasonModa({
              ...endReasonModal,
              show: false,
            });
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: true,
            });

            if (showEndPhaseModal.pIndex !== showEndPhaseModal.hIndex) {
              const dealDetails = pipeline
                ?.filter(
                  (pipeline) => pipeline.phase_id === showEndPhaseModal.pIndex
                )[0]
                .deals.filter(
                  (deal) => deal.id === showEndPhaseModal.dIndex
                )?.[0];

              if (dealDetails?.id) {
                changePhase(
                  dealDetails?.id,
                  showEndPhaseModal.pIndex,
                  showEndPhaseModal.hIndex,
                  reason
                )
                  .then((data) => {
                    successToast(
                      intl.formatMessage({
                        id: "DEALS_MOVE_SUCCESS_MESSAGE",
                      })
                    );
                  })
                  .catch((e) => {
                    errorToast(
                      intl.formatMessage({
                        id: "DEALS_MOVE_FAILURE_MESSAGE",
                      })
                    );
                  })
                  .finally(() => {
                    getDealPhaseAPI();
                  });
              }
            }
          }}
        />
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="">
          {/* search deals */}
          <div className="card d-flex flex-row justify-content-between mt-4 px-4 py-7">
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center">
                <SVGICON
                  src={SearchIcon}
                  className="svg-icon svg-icon-1 position-absolute ms-6"
                />
                <input
                  type="text"
                  className="form-control form-control-solid w-250px ps-15 mx-4"
                  placeholder={intl.formatMessage({
                    id: "DEALS_SEARCH_PLACEHOLDER",
                  })}
                  onChange={(e) => {
                    onSearchText(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenPipelineModal(true);
                }}
                className="d-flex align-items-center"
              >
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: "PIPELINE_INFO",
                  })}
                  data-tooltip-place="top"
                >
                  <i className="bi bi-info-circle-fill fs-3 text-primary"></i>
                </span>

                <Tooltip
                  id="my-tooltip"
                  place="top"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    zIndex: 999,
                  }}
                  border="1px solid green"
                />
              </div>
            </div>
            {/* add new button */}
            <div className="d-flex align-items-center justify-content-between mt-1">
              {crudPermission?.create && (
                <button
                  className="btn   btn-primary me-4"
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "DEALS_ADD_NEW_BUTTON" })}
                </button>
              )}
              <button
                className="btn btn-sm  btn-light-secondary me-4"
                onClick={() => {
                  const res =
                    DEAL_VIEW_TYPES.filter(
                      (view) => view.id !== currDealView.id
                    )?.[0] || [];
                  if (res && res.id) {
                    setCurrDealView(res);
                  }
                }}
              >
                <SVGICON
                  src={currDealView.id === 1 ? KanBanIcon : ListIcon}
                  className="svg-icon-2"
                />
              </button>
            </div>
          </div>
          {!loading && pipeline?.length === 0 && (
            <table className="d-flex justify-content-center align-items-center mt-2">
              <tbody>
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img
                        src={notFound}
                        className="mh-400px"
                        alt=""
                        style={{ color: "inherit" }}
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {loading ? (
            <table className="d-flex justify-content-center align-items-center">
              <tbody>
                <tr>
                  <td valign="top" colSpan={5} className="dataTables_empty">
                    <SimpleLoader fullLoader />
                  </td>
                </tr>
              </tbody>
            </table>
          ) : currDealView && currDealView.id === 2 ? (
            <div
              className={clsx(
                "pb-0 d-flex mt-4 gap-2 flex-row p-4 boxShadowNone overflow-scroll"
              )}
            >
              {displayPipeline?.map((phase, i) => {
                return (
                  <div className="card card-body p-0 dealColumn" key={i}>
                    <PhaseColumn
                      title={phase.name}
                      className={"column phase-column"}
                      index={phase.phase_id}
                      count={
                        phase.condition !== "end" ||
                        (phase.condition === "end" &&
                          phase.display_order !== null)
                          ? phase.deals.filter(
                              (data) => data.status === "inProgress"
                            ).length
                          : phase.deals.filter(
                              (data) =>
                                data.status === "finished" &&
                                phase.display_order === undefined
                            ).length
                      }
                      phase={phase}
                      userData={userData}
                    >
                      <>
                        {phase.deals &&
                          phase.deals.length > 0 &&
                          phase?.deals.map((item, index) => {
                            if (
                              (item.status === "inProgress" &&
                                phase.condition !== "end") ||
                              (item.status === "finished" &&
                                phase.condition === "end" &&
                                phase.display_order === null) ||
                              (item.status === "inProgress" &&
                                phase.condition === "end" &&
                                phase.display_order !== null)
                            ) {
                              const displayUsers = (
                                item.responsible_users || []
                              ).slice(0, 3);
                              const remainingUsers =
                                (item.responsible_users || []).length -
                                displayUsers.length;
                              return (
                                <MovableDeal
                                  key={index}
                                  name={item.name}
                                  currentPhaseId={phase.phase_id}
                                  currentPhaseName={phase.name}
                                  setItems={setItems}
                                  index={index}
                                  moveDealHandler={moveDealHandler}
                                  getDealPhaseAPI={getDealPhaseAPI}
                                  item={item}
                                  displayUsers={displayUsers}
                                  remainingUsers={remainingUsers}
                                  view={currDealView}
                                  currentPhase={phase}
                                  userData={userData}
                                />
                              );
                            }
                          })}
                      </>
                    </PhaseColumn>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <DealsListView phases={displayPipeline || []} pId={pipelineId} />
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default DealsList;
